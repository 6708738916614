import API from "api/index";
import React, { useState } from "react";
interface VideocallFeedbackProps {
  roomId: string;
  noteId: string;
}

export const VideocallFeedback = () => {
  return <div className="w-screen h-screen bg-primary">VideocallFeedback</div>;
};

export default VideocallFeedback;
